<template>
  <div>
    <div class="row justify-content-around">
      <div class="col-sm-12 col-md-12 card">
        <div class="title head">
          <p class="h2" style="color: #558cef">รายการทรัพย์สิน</p>
        </div>
        <hr />
        <!-- search input -->
        <div class="custom-search row justify-content-between">
          <div class="col-12 col-md-3 mb-1">
            <b-form-select v-model="statusA" :options="statusL" />
          </div>
          <b-form-group class="col-12 col-md-6">
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
              <div>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <b-button variant="outline-primary"
                      ><feather-icon icon="ShareIcon" class="mr-50" />Export
                      <feather-icon icon="ChevronDownIcon" class="mr-50"
                    /></b-button>
                  </template>
                 
                  <b-dropdown-item>
                    <feather-icon icon="FileIcon" class="mr-50" />
                    <span>Excel</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon icon="ClipboardIcon" class="mr-50" />
                    <span>Pdf</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'fullName'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="mx-1" />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-button
                  variant="outline-warning"
                  @click="$router.push({ name: 'tusinputkuruplun' })"
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                </b-button>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">of {{ props.total }} entries</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
      <!-- <div class="col-sm-12 col-md-2 card">
        <b-button @click="getListdate">test</b-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      statusA: null,
      statusL: [
        { value: null, text: "ทั้งหมด:" },
        { value: "เบิก", text: "เบิก" },
        { value: "ยืม", text: "ยืม" },
        { value: "คืน", text: "คืน" },
      ],
      columns: [
        {
          label: "เลขทะเบียน",
          field: "เลขทะเบียน",
        },
        {
          label: "กลุ่มงานผู้ขอเบิก",
          field: "กลุ่มงานผู้ขอเบิก",
        },
        {
          label: "วัน-เดือน-ปี",
          field: "วันที่",
        },
        {
          label: "ผู้เบิกพัสดุ",
          field: "ผู้เบิกพัสดุ",
        },
        {
          label: "สถานะทรัพย์สิน",
          field: "สถานะทรัพย์สิน",
        },
        {
          label: "ดูรายการ",
          field: "action",
        },
      ],
      rows: [
        {
          เลขทะเบียน: "ตร-001-60",
          กลุ่มงานผู้ขอเบิก: "กลุ่มงานผลิตและพัฒนาเครื่องมือวัดและประเมินผล",
          วันที่: "12/12/2565",
          ผู้เบิกพัสดุ: "เจ้าหน้าที่บริหารงานทั่วไป",
          สถานะทรัพย์สิน: "ยืม",
        },
        {
          เลขทะเบียน: "ตร-001-61",
          กลุ่มงานผู้ขอเบิก: "กลุ่มงานบริหารการทดสอบและศูนย์เครือข่าย",
          วันที่: "13/12/2565",
          ผู้เบิกพัสดุ: "เจ้าหน้าที่บริหารงานทั่วไป",
          สถานะทรัพย์สิน: "เบิก",
        },
        {
          เลขทะเบียน: "ตร-001-62",
          กลุ่มงานผู้ขอเบิก: "กลุ่มงานอำนวยการ",
          วันที่: "14/12/2565",
          ผู้เบิกพัสดุ: "เจ้าหน้าที่บริหารงานทั่วไป",
          สถานะทรัพย์สิน: "คืน",
        },
      ],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },

  mounted() {
    axios
      .get(
        "http://52.221.226.153:4000/visitorLog?_id=606ebd311570d80020e08728&_time=20211115&_page=1&_limit=10&_sort=1"
      )
      .then((response) => {
        this.users = response.data;
        // console.log(this.users);
      })
      .catch(function (error) {
        // console.log(error);
      });

    // console.log(Math.ceil(6.5))
  },
  methods: {
    async getListdate() {
      //   // const a = 'a'
      //   // const a = 15 ;
      //   // //  a = 17;
      //   //  console.log(a)
      //   // //  a = 20 ;
      //   //  console.log(a)
      //   // axios
      //   //   .get(
      //   //     'http://52.221.226.153:4000/visitorLog?_id=606ebd311570d80020e08728&_time=20211115&_page=1&_limit=10&_sort=1',
      //   //   )
      //   //   .then(function (response) {
      //   //     // handle success
      //   //     //console.log(response)
      //   //     a = response
      //   //   })
      //   //   .catch(function (error) {
      //   //     // handle error
      //   //     console.log(error)
      //   //   })
      //   //   .then(function () {
      //   //     // always executed
      //   //   })
      let data = "";
      try {
        data = await axios.get(
          "http://52.221.226.153:4000/visitorLog?_id=606ebd311570d80020e08728&_time=20211115&_page=1&_limit=10&_sort=1"
        );
      } catch (error) {
        // console.log(error);
      }
      //  a = data.data.message.result
      // console.log(data.data.message);
      return;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
